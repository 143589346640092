<template>
  <div>
    <b-card
      no-body
      class="p-3"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Urgente"
          >
            <b-form-checkbox
              v-model="previous.urgente"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Requiere revisión"
          >
            <b-form-checkbox
              v-model="previous.requiereRevision"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onSaveChanges"
      >
        {{ $t('Lists.saveChanges') }}
      </b-button>
    </b-card>
    <b-card
      no-body
      class="p-3"
    >
      <conversation ref="refConversation" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import previousService from '@/services/previous.service'
import Conversation from '@/components/Conversation.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,

    Conversation,
  },
  setup() {
    const {
      fetchPreviousById,
      partialUpdatePrevious,
    } = previousService()
    const refConversation = ref(null)
    const previous = ref({ urgente: true, requiereRevision: true })
    fetchPreviousById(router.currentRoute.params.previoId, data => {
      previous.value = data
      refConversation.value.refetchData(router.currentRoute.params.conversacionId)
    })
    const onSaveChanges = () => {
      partialUpdatePrevious(router.currentRoute.params.previoId, [
        {
          value: previous.value.urgente ? previous.value.urgente : false,
          path: 'urgente',
          op: 'replace',
        },
        {
          value: previous.value.requiereRevision ? previous.value.requiereRevision : false,
          path: 'requiereRevision',
          op: 'replace',
        },
      ], () => {
        router.push({ name: 'apps-previous-list' })
      })
    }
    return {
      refConversation,
      previous,
      onSaveChanges,
    }
  },
}
</script>

<style>

</style>
